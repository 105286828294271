.area{
    flex-direction: column;
    display: flex;
    justify-content: center;
    width: 100%;
    right: auto;
}

.banner_area{
    width: 100%;
    background-color:#2b2727;
}

.body_top{
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: top;
}

.banner {
    margin: auto;
    width: 100%;
    max-height: 550px;
    text-align: center;
}

.div_visao {
    
}
 
.area_serv{
margin-top: 40px;
text-align: center;
/*background-color: #dddddd;*/
background-image: url('/public/img/fundo_serv2.jpg') !important;
background-repeat: no-repeat;
background-size:cover;
}

.text_left {
    font-size:medium;
    text-align: left;
    font-weight: 400;
  }

.shadow_texte {
    font-weight: 300;
    text-shadow: 2px 2px 8px #ffffff;
}

.shadow_texte_2 {
    text-shadow: 2px 2px 8px #ffffff;
}

.area_serv_titulo {
    
}

.area_serv_body{
    padding: 0px;
}

.icon {
    width: 40px;
    margin-right: 10px;
}

.back_text_serv:hover {
        background-color: #000;
        transition-duration: 0.5s;
        color:#000;
}

.div_titulo_banner_pequeno {
    height: 90px;
    position: absolute;
    text-align: left;
    padding-left: 10px;
}

.text_banner_pequeno{
   padding-top: 35px;
}


.body_area {
   top: 0;
   left: 0;
}

.body_top_img{
    width: 30%;
    height: auto;
}

.body_top_text{
    width: 100%;
    height: auto;
    border: none;
}

.body_bottom {
    margin: auto;
    width: 80%;
}

.imgBannerLeft {
    width: 100%;
}

