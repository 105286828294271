@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  
  body {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    min-height: 90vh;
    
  }

  .div_text_animado{
    display: flex;
    width: 95%;
    margin: auto;
    padding-bottom: 40px;
  }

  .text_left {
    padding-left: 20px;
    padding-right: 20px;
    font-size:medium;
    font-weight: 400;
    text-align: 'justify';
    text-justify: 'inter-word'
  }

  
  .text_center {
    padding-top: 20px;
    text-align: center;
    text-decoration: underline;
  }
  
  section {
    box-sizing: border-box;
    border-bottom: 1px solid #dddddd;
    width: 90%;
    height: 47vh;
    display: flex;
    justify-content: center;
    overflow: hidden;
    background:  #ffffff;
  }
   
  section:nth-child(2) {
    background: #efefef;
  }
  
  section:nth-child(3) {
    background: #ffffff;
  }
  
  
  section span {
    display: block;
    transform: translateX(-100px);
    opacity: 0;
  }
  
  .titulo {
    text-decoration: underline;
  }

  @media only screen and (max-width: 540px) { 
    .div_text_animado{
      flex-direction: column;
    }

    section {
      width: 100%;
    }

    
}
  

