@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;800&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
    margin: 0;
    top: 0;
    left: 0;
    font-family: 'Open Sans', sans-serif;
    background-color: #EEE;
    flex-direction: row;
    height: auto;
    /*overflow: hidden;*/
}