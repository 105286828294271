.area_whatsapp {
    z-index: 99;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 42px;
    height: 42px;

    animation: whats 2s 4;
    animation-direction: alternate-reverse;
}

.area_whatsapp:hover {
    width: 40px;
    height: 40px;
    transition: cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes whats {
    0%   {bottom: 2rem; right: 2rem;}
    25%  {bottom: 2.5rem; right: 2rem;}
    50%  {bottom: 2rem; right: 2rem;}
    75%  {bottom: 2.5rem; right: 2rem;}
    100%  {bottom: 2rem; right: 2rem;}
  }